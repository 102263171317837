import React from 'react'

const Footer = ()=>{
    return (
        <div className="footer">
            <div className="footer-wrapper">
                <p><span>&#169;</span> 2022</p>
                <p>Built with passion</p>
            </div>
        </div>
    )
}

export default Footer
